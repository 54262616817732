import React from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../constants';
import './FlightRadioButton.scss';


const FlightRadioButton = (props) => {
  const {
    disabled, checked, label, className, onSelect, value,
  } = props;

  const handleSelect = () => {
    if (disabled) return;
    if (onSelect) {
      onSelect(value);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === EVENT_KEYS.ENTER) {
      handleSelect();
    }
  };

  const mainClass = 'flight-radio-button';
  const labelClass = `${mainClass}__span`;
  const labelElement = label ? (
    <span className={labelClass}>{label}</span>
  ) : null;

  let wrapperClass = `${mainClass}-wrapper`;
  wrapperClass += disabled ? ` ${wrapperClass}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';

  let radioClass = `${mainClass}__radio`;
  radioClass += checked ? ` ${radioClass}--selected` : '';

  return (
    <div className={wrapperClass}>
      <div
        className={mainClass}
        role="button"
        tabIndex={disabled ? -1 : 0}
        onClick={handleSelect}
        onKeyPress={handleKeyPress}
      >
        <div className={radioClass} />
        {labelElement}
      </div>
    </div>
  );
};

FlightRadioButton.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  /* eslint-disable react/forbid-prop-types */
  value: PropTypes.any.isRequired,
};

FlightRadioButton.defaultProps = {
  label: '',
  checked: false,
  className: '',
  disabled: false,
  onSelect: () => undefined,
};

export default FlightRadioButton;
