import React from 'react';
import PropTypes from 'prop-types';
import './FlightAvatar.scss';


const DEFAULT_CLASS = 'flight-avatar';

const FlightAvatar = (props) => {
  const {
    firstName, lastName, email, className,
  } = props;

  let fixedInitial = firstName && firstName.length ? (
    firstName[0].toLocaleUpperCase()
  ) : '';
  if (!fixedInitial) {
    fixedInitial = email && email.length ? (
      email[0].toLocaleUpperCase()
    ) : '';
  }
  let mainClass = DEFAULT_CLASS;
  mainClass += (fixedInitial && fixedInitial.length
    ? ` ${DEFAULT_CLASS}--${fixedInitial.toLocaleLowerCase()}` : ''
  );
  mainClass += className ? ` ${className}` : '';
  return (
    <div className={mainClass}>
      {fixedInitial}
      {lastName && lastName.length ? (
        lastName[0].toLocaleUpperCase()
      ) : ''}
    </div>
  );
};

FlightAvatar.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  className: PropTypes.string,
};

FlightAvatar.defaultProps = {
  firstName: '',
  lastName: '',
  email: '',
  className: '',
};

export default FlightAvatar;
