import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../../constants';
import FlightButton from '../../flight-button/FlightButton';
import FlightNumberInput from '../../flight-number-input/FlightNumberInput';
import FlightSelect from '../../flight-select/FlightSelect';
import './FlightTablePagination.scss';


const DEFAULT_CLASS = 'flight-table-pagination';
const ROW_SELECT_CLASS = `${DEFAULT_CLASS}__row-select`;
const PAGE_CONTROL_CLASS = `${DEFAULT_CLASS}__page-control`;

const FlightTablePagination = (props) => {
  const {
    className, totalPageNumber, currentPageNumber, rowsPerPageOptions,
    handlePageChange, handleRowsPerPageChange, currentRowsPerPage,
  } = props;
  const [inputValue, setInputValue] = useState(currentPageNumber.toString());
  useEffect(() => {
    setInputValue(currentPageNumber.toString());
  }, [currentPageNumber]);
  const handleOptionClick = (option) => {
    if (option && option.key) {
      handleRowsPerPageChange(option.key);
    }
  };
  const handleInputKeyPress = (event) => {
    if (event.key === EVENT_KEYS.ENTER) {
      handlePageChange(Number(event.target.value));
    }
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleInputBlur = () => {
    setInputValue(currentPageNumber.toString());
  };

  let mainClass = DEFAULT_CLASS;
  mainClass += className ? ` ${className}` : '';
  return (
    <div className={mainClass}>
      <div className={ROW_SELECT_CLASS}>
        <FlightSelect
          options={rowsPerPageOptions.map((option) => ({
            key: option,
            name: option.toString(),
          }))}
          selected={{
            key: currentRowsPerPage,
            name: currentRowsPerPage.toString(),
          }}
          handleOptionClick={handleOptionClick}
          width="65px"
        />
        <span className={`${ROW_SELECT_CLASS}__label`}>per page</span>
      </div>
      <div className={PAGE_CONTROL_CLASS}>
        <FlightButton
          theme="minor"
          iconLeft="baselineKeyboardArrowLeft"
          disabled={currentPageNumber === 1}
          onClick={() => handlePageChange(currentPageNumber - 1)}
        />
        <FlightButton
          theme="minor"
          iconLeft="baselineKeyboardArrowRight"
          disabled={currentPageNumber === totalPageNumber}
          onClick={() => handlePageChange(currentPageNumber + 1)}
        />
        <FlightNumberInput
          className={`${PAGE_CONTROL_CLASS}__page-input`}
          maxValue={totalPageNumber}
          minValue={1}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyPress={handleInputKeyPress}
          value={inputValue}
          width="38px"
        />
        <FlightButton
          label={`/ ${totalPageNumber}`}
          theme="link"
          hasUnderline
          onClick={() => handlePageChange(totalPageNumber)}
        />
      </div>
    </div>
  );
};

FlightTablePagination.propTypes = {
  className: PropTypes.string,
  totalPageNumber: PropTypes.number,
  currentPageNumber: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  currentRowsPerPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRowsPerPageChange: PropTypes.func,
};

FlightTablePagination.defaultProps = {
  className: '',
  totalPageNumber: 1,
  currentPageNumber: 1,
  rowsPerPageOptions: [10, 20, 40, 60],
  currentRowsPerPage: 20,
  handlePageChange: () => undefined,
  handleRowsPerPageChange: () => undefined,
};

export default FlightTablePagination;
