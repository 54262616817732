import React from 'react';
import PropTypes from 'prop-types';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './FlightDateTimePicker.scss';


const DEFAULT_CLASS = 'flight-datetime-picker';

const FlightDateTimePicker = (props) => {
  const {
    onChange, value, format, disabled, disableFuture, disablePast,
    minDate, maxDate, label, readOnly, isError, showTodayButton,
    minutesStep, allowKeyboardControl,
  } = props;

  const pickerProps = {
    value,
    onChange,
    label,
    readOnly,
    format,
    disabled,
    disableFuture,
    disablePast,
    showTodayButton,
    minutesStep,
    allowKeyboardControl,
  };
  if (minDate) {
    pickerProps.minDate = minDate;
  }
  if (maxDate) {
    pickerProps.maxDate = maxDate;
  }

  let mainClass = DEFAULT_CLASS;
  mainClass += isError ? ` ${DEFAULT_CLASS}--error` : '';

  return (
    <div className={mainClass}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker {...pickerProps} />
      </MuiPickersUtilsProvider>
    </div>
  );
};

FlightDateTimePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isError: PropTypes.bool,
  readOnly: PropTypes.bool,
  showTodayButton: PropTypes.bool,
  minutesStep: PropTypes.number,
  allowKeyboardControl: PropTypes.bool,
};

FlightDateTimePicker.defaultProps = {
  value: null,
  label: '',
  format: 'yyyy-MM-dd hh:mm a',
  disabled: false,
  disableFuture: false,
  disablePast: false,
  minDate: null,
  maxDate: null,
  isError: false,
  readOnly: false,
  showTodayButton: true,
  minutesStep: 5,
  allowKeyboardControl: true,
};

export default FlightDateTimePicker;
