import React from 'react';
import PropTypes from 'prop-types';
import './FlightTableRow.scss';


const DEFAULT_CLASS = 'flight-table-row';

const FlightTableRow = (props) => {
  const {
    item, columns, allowRowSelect, selected, onClick,
  } = props;
  let mainClass = DEFAULT_CLASS;
  mainClass += allowRowSelect ? ` ${DEFAULT_CLASS}--select-effect` : '';
  mainClass += selected ? ` ${DEFAULT_CLASS}--selected` : '';
  return (
    <tr className={mainClass} onClick={onClick || (() => undefined)}>
      {
        columns.map((col) => <td className={`${DEFAULT_CLASS}__cell`} key={col}>{item[col]}</td>)
      }
    </tr>
  );
};

FlightTableRow.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string),
  allowRowSelect: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

FlightTableRow.defaultProps = {
  columns: [],
  selected: false,
};

export default FlightTableRow;
