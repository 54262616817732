import React from 'react';
import PropTypes from 'prop-types';
import './FlightTag.scss';


const TAG_TYPES_MAP = {
  default: 'default',
  success: 'success',
  error: 'error',
  info: 'info',
};

const FlightTag = (props) => {
  const { label, type, className } = props;

  let mainClass = 'flight-tag';
  mainClass += (TAG_TYPES_MAP[type] ? ` ${mainClass}--${TAG_TYPES_MAP[type]}`
    : ` ${mainClass}--${TAG_TYPES_MAP.default}`);
  return (
    <span className={`${mainClass}${className ? ` ${className}` : ''}`}>
      {label}
    </span>
  );
};

FlightTag.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

FlightTag.defaultProps = {
  label: '',
  type: 'default',
  className: '',
};

export default FlightTag;
