/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../constants';
import FlightButton from '../flight-button/FlightButton';
import FlightDropdown from '../flight-dropdown/FlightDropdown';
import './FlightSelect.scss';


const DEFAULT_CLASS = 'flight-select';
const LABEL_CLASS = `${DEFAULT_CLASS}__label`;
const BORDER_CLASS = `${DEFAULT_CLASS}__border`;
const DROPDOWN_CLASS = `${DEFAULT_CLASS}__dropdown`;
const DROPDOWN_OPTION_CLASS = `${DROPDOWN_CLASS}__option`;
const ERROR_CLASS = `${DEFAULT_CLASS}__error-message`;

const FlightSelect = (props) => {
  const {
    className, label, options, selected, dropdownMaxHeight,
    dropdownDirection, hasLabelAnimation, handleOptionClick,
    disabled, hasError, errorMessage,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  // isFocused for handling styles changes when user
  //  is trying to do keyboard focus.
  const [isFocused, setIsFocused] = useState(false);
  const [focusedOptIdx, setFocusedOptIdx] = useState(null);
  const wrapperRef = (element) => {
    if (element) {
      // Resize component width based on props.width
      /* eslint-disable no-param-reassign */
      element.style.width = props.width;
    }
  };
  const triggerRef = useRef(null);
  // for focusing on a dropdown option.
  const dropdownRef = useRef(null);
  const closeDropdown = (isFocusTrigger) => {
    setIsOpen(false);
    if (isFocusTrigger) triggerRef.current.focus();
  };
  const onOptionClick = (option, isCloseDropdown) => {
    handleOptionClick(option);
    if (isCloseDropdown) closeDropdown(true);
  };
  const focusOnOption = (matchedOption) => {
    if (dropdownRef && dropdownRef.current) {
      const dropdownOptions = dropdownRef.current.children;
      if (matchedOption) {
        for (let optIdx = 0; optIdx < dropdownOptions.length; ++optIdx) {
          if ((matchedOption.name
            && dropdownOptions[
              optIdx
            ].attributes.helperkey.value === matchedOption.key)) {
            dropdownOptions[optIdx].focus();
            break;
          }
        }
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      if (selected && selected.key) {
        // auto focus on selected option when dropdown opens
        setFocusedOptIdx(options.findIndex(
          (opt) => opt.key === selected.key,
        ));
        focusOnOption(selected);
      } else if (options && options.length > 0) {
        // if props.selected is not defined, auto selects
        //  the first option in the list of the list is
        //  not empty.
        setFocusedOptIdx(0);
        focusOnOption(options[0]);
      }
    }
  }, [isOpen]);
  const handleFindOptionOnKeyDown = (event) => {
    const reg = new RegExp('^[a-zA-Z0-9]$');
    if (isOpen) {
      if (reg.test(event.key)) {
        // finds the first option in the list that matches the
        //  letter or number the user just typed.
        const matchedOption = options.find(
          (opt) => (opt.name.length
            && opt.name[0].toLocaleLowerCase()
            === event.key.toLocaleLowerCase()),
        );
        focusOnOption(matchedOption);
      } else if (options && options.length > 0 && [
        EVENT_KEYS.ARROW_UP,
        EVENT_KEYS.ARROW_DOWN,
      ].includes(event.key)) {
        // focuses on the previous/next option when users
        //  input arrow up/down actions using keybard.
        const newFocusedOptIdx = (
          event.key === EVENT_KEYS.ARROW_UP
            ? Math.max(focusedOptIdx - 1, 0)
            : Math.min(focusedOptIdx + 1, options.length - 1));
        setFocusedOptIdx(newFocusedOptIdx);
        focusOnOption(options[newFocusedOptIdx]);
        event.preventDefault();
      } else if (event.key === EVENT_KEYS.TAB) {
        // when users enter tab key they exit the component.
        closeDropdown();
      } else if (event.key === EVENT_KEYS.ESCAPE) {
        // when users enter escape key, close the dropdown but
        //  focus back to trigger button.
        closeDropdown(true);
      }
    }
  };

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += (label && label.length && hasLabelAnimation
    ? ` ${DEFAULT_CLASS}--label-animation` : '');
  wrapperClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';

  let dropdownClass = DROPDOWN_CLASS;
  dropdownClass += isFocused ? ` ${DROPDOWN_CLASS}--focused` : '';
  dropdownClass += hasError ? ` ${DROPDOWN_CLASS}--error` : '';

  let borderClass = BORDER_CLASS;
  borderClass += isOpen ? ` ${BORDER_CLASS}--open` : '';
  borderClass += hasError ? ` ${BORDER_CLASS}--error` : '';

  let labelClass = LABEL_CLASS;
  labelClass += isOpen ? ` ${LABEL_CLASS}--open` : '';
  labelClass += ((hasLabelAnimation && (isOpen
    || (selected && selected.key))) ? ` ${LABEL_CLASS}--up` : '');
  labelClass += hasError ? ` ${LABEL_CLASS}--error` : '';

  let errorMessageClass = ERROR_CLASS;
  errorMessageClass += disabled ? ` ${ERROR_CLASS}--disabled` : '';

  return (
    <div
      className={wrapperClass}
      ref={wrapperRef}
      onKeyDown={handleFindOptionOnKeyDown}
      role="button"
      tabIndex="-1"
    >
      <FlightDropdown
        className={dropdownClass}
        trigger={(
          <FlightButton
            className={`${DROPDOWN_CLASS}__trigger__button`}
            label={selected && selected.name ? selected.name : ' '}
            theme="minor"
            onBlur={() => setIsFocused(false)}
            onClick={() => setIsOpen(true)}
            onFocus={() => setIsFocused(true)}
            iconRight={isOpen ? 'upCarrot' : 'downCarrot'}
            disabled={disabled}
            buttonRef={triggerRef}
            isPropagateUpperActions
          />
        )}
        direction={dropdownDirection}
        maxHeight={dropdownMaxHeight}
        isActive={isOpen}
        handleClickOutside={() => closeDropdown(true)}
      >
        <div ref={dropdownRef}>
          {options && options.length ? (
            options.map((option) => (
              <FlightButton
                key={option.key}
                helperkey={option.key}
                className={`${DROPDOWN_OPTION_CLASS}${
                  selected && selected.key === option.key
                    ? ` ${DROPDOWN_OPTION_CLASS}--selected` : ''
                }`}
                label={option.name}
                theme="minor"
                onClick={() => onOptionClick(option, true)}
              />
            ))
          ) : (
            <span className={`${DROPDOWN_CLASS}__empty-message`}>
              No options available
            </span>
          )}
        </div>
      </FlightDropdown>
      {label && label.length && (!selected || hasLabelAnimation) && (
        <div className={labelClass}>{label}</div>
      )}
      <div className={borderClass} />
      {hasError && errorMessage && (
        <span className={errorMessageClass}>{errorMessage}</span>
      )}
    </div>
  );
};

FlightSelect.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
  })),
  selected: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
  }),
  hasLabelAnimation: PropTypes.bool,
  handleOptionClick: PropTypes.func,
  dropdownMaxHeight: PropTypes.string,
  dropdownDirection: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
};

FlightSelect.defaultProps = {
  label: 'Select an option',
  className: '',
  options: [],
  selected: null,
  hasLabelAnimation: false,
  handleOptionClick: () => undefined,
  dropdownMaxHeight: '200px',
  dropdownDirection: '',
  hasError: false,
  errorMessage: '',
  width: '232px',
  disabled: false,
};

export default FlightSelect;
