import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './FlightDropdown.scss';


const DEFAULT_CLASS = 'flight-dropdown';
const OUTSIDE_CLASS = `${DEFAULT_CLASS}__outside`;
const DROPDOWN_CLASS = `${DEFAULT_CLASS}__dropdown`;

const FlightDropdown = (props) => {
  const {
    children, className, direction, trigger, maxHeight,
    maxWidth, isActive, isControlledByIsActive,
    handleTriggerClick, handleClickOutside,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOnTriggerClick = (event) => {
    setIsOpen(true);
    handleTriggerClick(event);
  };

  const handleOnClickOutside = (event) => {
    setIsOpen(false);
    handleClickOutside(event);
  };

  const dropdownRef = (element) => {
    if (element) {
      /* eslint-disable no-param-reassign */
      if (maxHeight) element.style.maxHeight = maxHeight;
      if (maxWidth) element.style.maxWidth = maxWidth;

      if (isActive && (isOpen || isControlledByIsActive)) {
        const dropdownRect = element.getBoundingClientRect();
        if (dropdownRect.bottom > window.innerHeight
          && direction !== 'bottom') {
          element.classList.add(`${DROPDOWN_CLASS}--top`);
        } else if (dropdownRect.top < 0
          && !element.className.includes(` ${DROPDOWN_CLASS}--top`)) {
          element.classList.add(`${DROPDOWN_CLASS}--bottom`);
        } else if (dropdownRect.right > window.innerWidth
          && element.className.includes(` ${DROPDOWN_CLASS}--right`)) {
          element.classList.add(`${DROPDOWN_CLASS}--left`);
        } else if (dropdownRect.left - dropdownRect.width < 0
          && element.className.includes(` ${DROPDOWN_CLASS}--left`)) {
          element.classList.add(`${DROPDOWN_CLASS}--right`);
        } else if (dropdownRect.right > window.innerWidth
          && !element.className.includes(` ${DROPDOWN_CLASS}--top`)) {
          element.classList.add(`${DROPDOWN_CLASS}--bottom-left`);
        }
      }
    }
  };

  return (
    <div className={`${DEFAULT_CLASS}${className ? ` ${className}` : ''}`}>
      <div
        className={`${DEFAULT_CLASS}__trigger`}
        onClick={handleOnTriggerClick}
        onKeyPress={() => undefined}
        role="button"
        tabIndex="-1"
      >
        {trigger}
      </div>
      {isActive && (isOpen || isControlledByIsActive) && (
        <React.Fragment>
          <div
            className={OUTSIDE_CLASS}
            onClick={handleOnClickOutside}
            onKeyPress={() => undefined}
            role="button"
            tabIndex="-1"
            aria-label="dropdown-outside"
          />
          <div
            className={`${DROPDOWN_CLASS}${direction
              ? ` ${DROPDOWN_CLASS}--${direction}` : ''}`}
            ref={dropdownRef}
            tabIndex="-1"
          >
            {children}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

FlightDropdown.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  direction: PropTypes.string,
  trigger: PropTypes.element.isRequired,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  isActive: PropTypes.bool,
  isControlledByIsActive: PropTypes.bool,
  handleTriggerClick: PropTypes.func,
  handleClickOutside: PropTypes.func,
};

FlightDropdown.defaultProps = {
  className: '',
  direction: '',
  maxHeight: null,
  maxWidth: null,
  isActive: true,
  isControlledByIsActive: false,
  handleTriggerClick: () => undefined,
  handleClickOutside: () => undefined,
};

export default FlightDropdown;
